<template>
    <v-col cols="12">
        <div>
            <v-card
            v-for="hotel in hotels"
            :key="hotel.HotelIndex"
            class="my-10"
            :height="$vuetify.breakpoint.mdAndUp ? '300px' : '500'"
            >
                <v-row>
                    <v-col cols="12" sm="4" class="py-0">
                        <v-img :height="$vuetify.breakpoint.mdAndUp ? '300' : '150'" :alt="hotel.HotelName" v-if="hotel.HotelPicture" class="image rounded-l" :src="hotel.HotelPicture"></v-img>
                        <v-img :height="$vuetify.breakpoint.mdAndUp ? '300' : '150'" :alt="hotel.HotelName" v-else class="image rounded-l" src="../../assets/kaaba.svg">
                            <div class="fill-height bottom-gradient"></div>
                        </v-img>
                    </v-col>
                    <v-col cols="12" sm="8" class="pl-0 pb-0">
                        <v-card-title
                        class="py-0"
                        >
                            <v-row no-gutters>
                                <v-col cols="12" md="8" >
                                    <div class="primary--text body-1 font-weight-bold pt-0 text-truncate" style="word-break: break-word;">
                                        {{hotel.HotelName}}
                                    </div>
                                    <span class="pl-0 caption grey--text"><v-icon left>mdi-map-marker</v-icon> {{hotel.HotelCityName}}</span>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <div class="text-right">
                                        <v-rating class="mt-2 mb-0 mr-3" dense :length="hotel.stars" color="warning" readonly x-small  :value="parseInt(hotel.stars)" full-icon="mdi-star"></v-rating>
                                        <span class="primary--text body-1" v-if="hotel.TripAdviserRating">Ranting: {{hotel.TripAdviserRating}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text class="pb-3">
                            <div>
                                <v-subheader class="pl-0">{{getNights()}} Nights, {{getGuestsNum()}} Guests</v-subheader>
                                <p class="text-h5 font-weight-bold primary--text">{{hotel.pricingInfo.totalPrice.toFixed(2) | changeCurrency}}</p>
                                <!-- <p class="pl-0 text-truncate" v-html="hotel.HotelDescription"></p> -->
                                <div class="hello">
                                    <read-more more-str="read more" :text="hotel.HotelDescription" link="#" less-str="read less" :max-chars="150"></read-more>
                                    <!-- <read-more more-str="read more" less-str="read less" :text="hotel.HotelDescription" link="#"></read-more> -->
                                </div>
                            </div>
                            <v-btn
                            v-if="$route.name !== 'packageBook'"
                            tile
                            color="primary white--text"
                            elevation="0"
                            class="rounded-lg mt-3"
                            @click="$emit('getRooms', hotel.HotelIndex, hotel.HotelCode)"
                            >
                                See availability
                            </v-btn>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </v-col>
</template>

<script>
export default {
  props: ['hotels', 'result', 'session', 'type', 'hotel'],
  data () {
    return {
      showPlaceholderImage: false,
      src: ''
    }
  },
  methods: {
    getNights () {
      return this.$route.name === 'meccaHotels' ? this.$store.state.meccaNights : this.$store.state.medinaNights
    },
    getGuestsNum () {
      let adults = 0
      let children = 0
      for (let index = 0; index < this.result.roomGuests.length; index++) {
        adults += this.result.roomGuests[index].Adults
        children += this.result.roomGuests[index].Children
      }
      return adults + children
    },
    select (index, code, rooms) {
      const spentTime = Date.now() - this.now
      const timer = (20 * 60 * 1000) - spentTime
      localStorage.setItem('hotelsTimer', timer)
      const routeData = this.$router.resolve({ name: 'oneHotel', params: { sessionId: this.session, hotelIndex: index, hotelCode: code, roomsNo: rooms } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style>
  .bottom-gradient {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(-45deg,
    rgba(255,0,0,.05),
    rgba(255,0,0,.05) 5px,
    rgba(0,0,255,.05) 5px,
    rgba(0,0,255,.05) 10px
    );
  }
</style>
