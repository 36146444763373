<template>
    <div>
        <!-- <overlay v-if="$store.state.hotelTimeOut && hotels" @newSearch="getHotels" /> -->
        <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
            {{ text }}
            <v-btn icon @click="snackbar = false"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-overlay :value="overlay">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
        <v-container fluid>
            <v-row justify="center" align="center" style="height: 60vh" v-if="!hotels && loaded">
                <div>
                    <v-img max-width="200" class="blink-2" src="../../assets/logo-adam.png"></v-img>
                </div>
            </v-row>
            <v-row v-if="hotels && hotels !== {} && !loaded">
              <v-col cols="12" v-if="hotels.Hotels.length">
                <v-card class="d-flex justify-space-between align-center flex-wrap pa-5">
                  <p class="headline primary--text mb-0">Price Filter</p>
                  <div class="d-flex flex-wrap">
                    <v-text-field prefix="$" dense hide-details type="number" hide-controls :min="hotels.minPrice" :max="maxPrice" v-model="minPrice" label="Min" outlined class="ma-1"></v-text-field>
                    <v-text-field prefix="$" dense hide-details type="number" hide-controls :min="minPrice" :max="hotels.maxPrice" v-model="maxPrice" label="Max" outlined class="ma-1"></v-text-field>
                    <v-btn elevation="0" color="primary" dark @click="filterByPrice" height="40" class="ma-1">Filterate</v-btn>
                    <v-btn elevation="0" color="primary" dark @click="resetFilter" height="40" class="ma-1">Reset</v-btn>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12">
                <hotelsCard v-if="hotels.Hotels.length" :result="hotels" :hotels="filteredHotels" :session="hotels.session_id" @getRooms="getHotelRooms"></hotelsCard>
                <p v-else class="body-1 font-weight-bold primary--text text-center mt-5">
                  Sorry, No hotels match your search in this city. <v-btn text color="blue" @click="$router.push('/')">BACK TO search</v-btn>
                </p>
              </v-col>
            </v-row>
            <p v-if="(hotels === {} && !loaded) || (!hotels && !loaded)" class="body-1 font-weight-bold primary--text text-center mt-5">
                Sorry, No hotels match your search in this city. <v-btn text color="blue" @click="$router.push('/')">BACK TO search</v-btn>
            </p>
        </v-container>

        <v-dialog v-model="showRoomsDialog" fullscreen hide-overlay transition="dialog-bottom-transition" >
          <v-card v-if="hotelAndRooms">
            <v-toolbar color="primary">
              <v-toolbar-title class="white--text">{{ hotelAndRooms.Hotel.HotelName }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn icon dark @click="showRoomsDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <hotel :hotel="hotelAndRooms" @roomReserved="redirect()" />

          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import hotelsAPI from '@/requests/hotels.js'
import hotelsCard from '@/components/hotels/hotelCard'
import hotel from '@/components/hotels/hotel'

import { mapState } from 'vuex'
// import overlay from "../components/overlay"

export default {
  components: {
    hotelsCard,
    hotel
    // hotelSearch,
    // overlay,
    // hotelFilters,
  },
  data () {
    return {
      hotelsNum: 0,
      city: '',
      hotels: null,
      filteredHotels: [],
      snackbar: false,
      color: '',
      text: '',
      loaded: true,
      hotelAndRooms: null,
      showRoomsDialog: false,
      overlay: false,
      minPrice: 0,
      maxPrice: 0
    }
  },
  computed: {
    ...mapState(['destinationAirport', 'flight', 'meccaNights', 'medinaNights', 'returnDate', 'roomsAndoccupancy', 'meccaHotel', 'medinaHotel'])
  },
  methods: {
    filterByPrice () {
      this.filteredHotels = this.hotels.Hotels.filter((a) => (a.pricingInfo.totalPrice >= this.minPrice && a.pricingInfo.totalPrice <= this.maxPrice))
    },
    resetFilter () {
      this.minPrice = this.hotels.minPrice
      this.maxPrice = this.hotels.maxPrice
      this.filteredHotels = this.hotels.Hotels
    },
    searchHotels () {
      const payload = {
        isHotel: 0,
        checkIn: '',
        checkOut: '',
        code: this.$route.name === 'meccaHotels' ? 1 : 2,
        roomGuests: []
      }
      this.roomsAndoccupancy.forEach(room => {
        payload.roomGuests.push({
          adults: room.adults,
          children: room.children.length,
          childAge: room.children.map(age => age.age)
        })
      })
      payload.roomGuests.forEach(element => {
        if (element.children === 0) {
          delete element.childAge
          delete element.children
        }
      })
      if ((this.destinationAirport.Code === 'JED' && this.$route.name === 'meccaHotels') || (this.destinationAirport.Code === 'MED' && this.$route.name === 'meccaHotels' && this.medinaNights === 0)) {
        payload.checkIn = this.flight.flightSegments[0].leg.arrivalDate
        const date = new Date(payload.checkIn)
        date.setDate(date.getDate() + parseInt(this.meccaNights))
        payload.checkOut = date.toISOString().substr(0, 10)
      } else if (this.destinationAirport.Code === 'MED' && this.$route.name === 'meccaHotels' && this.medinaNights > 0) {
        payload.checkOut = this.returnDate
        const date = new Date(payload.checkOut)
        date.setDate(date.getDate() - parseInt(this.meccaNights))
        payload.checkIn = date.toISOString().substr(0, 10)
      } else if (this.destinationAirport.Code === 'MED' && this.$route.name === 'medinaHotels' && this.medinaNights > 0) {
        payload.checkIn = this.flight.flightSegments[0].leg.arrivalDate
        const date = new Date(payload.checkIn)
        date.setDate(date.getDate() + parseInt(this.medinaNights))
        payload.checkOut = date.toISOString().substr(0, 10)
      } else if (this.destinationAirport.Code === 'JED' && this.$route.name === 'medinaHotels' && this.medinaNights > 0) {
        payload.checkOut = this.returnDate
        const date = new Date(payload.checkOut)
        date.setDate(date.getDate() - parseInt(this.medinaNights))
        payload.checkIn = date.toISOString().substr(0, 10)
      }
      hotelsAPI.getHotels(payload).then(response => {
        this.hotels = response.data.data
        if (this.hotels.Hotels.length) {
          this.hotels.Hotels.sort((a, b) => (a.pricingInfo.totalPrice > b.pricingInfo.totalPrice) ? 1 : ((b.pricingInfo.totalPrice > a.pricingInfo.totalPrice) ? -1 : 0))
          this.filteredHotels = this.hotels.Hotels
          this.minPrice = this.hotels.minPrice
          this.maxPrice = this.hotels.maxPrice
        }
        this.$store.dispatch('setHotel', { city: this.$route.name === 'meccaHotels' ? 'mecca' : 'medina', hotels: this.hotels })
      }).catch(() => {
        this.loaded = false
        this.snackbar = true
        this.color = 'error'
        this.text = 'Couldnot retrieve hotels'
      }).finally(() => {
        this.loaded = false
      })
    },
    getHotelRooms (index, code) {
      this.overlay = true
      hotelsAPI.getHotel(code, this.$route.name === 'meccaHotels' ? this.meccaHotel.session_id : this.medinaHotel.session_id).then(response => {
        this.hotelAndRooms = response.data.data
        this.showRoomsDialog = true
      }).catch(() => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Couldnot retrieve hotels'
        this.overlay = false
      }).finally(() => {
        this.overlay = false
      })
    },
    redirect (params) {
      this.showRoomsDialog = false
      if (parseInt(this.$route.params.stop) === 1) {
        if (this.$route.name === 'meccaHotels') {
          if (this.medinaNights > 0) {
            this.$router.push({ name: 'medinaHotels', params: { stop: 2 } })
          } else {
            this.$router.push({ name: 'packageBook', params: { id: this.$store.state.packageDetails.id } })
          }
        } else {
          this.$router.push({ name: 'meccaHotels', params: { stop: 2 } })
        }
      } else this.$router.push({ name: 'packageBook', params: { id: this.$store.state.packageDetails.id } })
    }
  },
  watch: {
  },
  beforeRouteUpdate (to, from, next) {
    this.searchHotels()
    next()
  },
  created () {
    window.scrollTo(0, 0)
    if (!this.flight) this.$router.push('/')
    this.searchHotels()
  }
}
</script>

<style scoped>

.blink-2 {
    -webkit-animation: blink-2 1.5s infinite both;
    animation: blink-2 1.5s infinite both;
}

@-webkit-keyframes blink-2 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blink-2 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}
</style>
