// /airport/autocomplete?term=${term}
import Vue from 'vue'
import { baseAPIURL, bookingEngineV2 } from '../APILinks'

const timeout = 120000
export default {
  // get hotels request
  getHotels (payload) {
    return Vue.axios.post(baseAPIURL + 'hotel/search',
      payload,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // get hotel details
  getHotel (hotelCode, sessionId) {
    return Vue.axios.get(bookingEngineV2 + `hotels/show?hotelCode=${hotelCode}&sessionId=${sessionId}`,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      })
  },

  // get hotel details
  checkHotelPrice (params) {
    return Vue.axios.get(bookingEngineV2 + `hotels/room/availability?roomIndex=${params.roomIndex}&sessionId=${params.sessionId}&ratePlanCode=${params.ratePlanCode}&bedGroupId=${params.bedGroupId}`,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      })
  },

  // hold hotel
  holdHoetel (body) {
    return Vue.axios.post(bookingEngineV2 + 'hotels/hold-booking',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  },

  // book hotels
  bookHotels (body) {
    return Vue.axios.post(bookingEngineV2 + 'umrah-express/book',
      body,
      {
        timeout: timeout,
        headers: { Accept: 'application/json', 'Content-type': 'application/json' }
      })
  }
}
