<template>
    <results></results>
</template>

<script>
import results from './results'

export default {
  components: {
    results
  }
}
</script>
